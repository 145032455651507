<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
      <v-btn color="primary" @click="linkDialog = true" title="Пригласить"><btn-title icon="far fa-plus-square">Пригласить</btn-title></v-btn>
    </template>
    <portal to="v-main">
      <dialog-edit2 v-model="linkDialog" maxWidth="400px">
        <template v-slot:title> Регистрация партнера </template>
        <div class="d-flex">
          <v-text-field v-model="linkRegistration" dense readonly outlined label="Ссылка регистрации" />
          <v-btn class="ml-2" @click="copyToClipboard(linkRegistration)"><v-icon>fas fa-clone</v-icon></v-btn>
        </div>
        <div class="d-flex">
          <v-text-field :value="linkPage" dense readonly outlined label="Ссылка на страницу" />
          <v-btn class="ml-2" @click="copyToClipboard(linkPage)"><v-icon>fas fa-clone</v-icon></v-btn>
        </div>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn @click="linkDialog = false">Закрыть</v-btn>
        </template>
      </dialog-edit2>
    </portal>
    <a-table-f-api ref="table" :api="url" :model="model" :useQuery="true" :defaults="defaults" @click="onClickRow($event)" />
  </static-fullscreen-card>
</template>

<script>
import { getAccess, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, genModel],
  components: {},
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      linkDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.users,
      url: "/signup/get_partner",
      defaults: {
        sort: { key: "id", order: "ASC" },
      },
    };
  },
  created() {
    this.$root.title = "Партнеры";
  },
  computed: {
    linkRegistration() {
      return "https://lk.gk-mechti.ru/#/registration?partner=" + this.$root.profile.partner_ref;
    },
    linkPage() {
      return "https://gk-mechti.ru/partner/?p=" + this.$root.profile.partner_ref;
    },
    model() {
      let model = this.calcModel("partnerList", "list");
      model.find((m) => m.name == "");
      return model;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert("Скопировано в буфер обмена!");
        })
        .catch((error) => {
          console.error("Не удалось скопировать: ", error);
        });
    },
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
